import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AddKirtanStepper from "./Components/AddKirtan";
import KirtanArea from "./Components/KirtanArea";
import { setDbStatus } from "./Slice/dbSlice";
import { setPreSettings } from "./Slice/settingsSlice";
import IndexedDBService, { tblMySettings } from "./Utils/DBConfig";
import PreSetting from "./Utils/PreSetting.json";

function App() {
  const dispatch = useDispatch();
  // const [preSetting, setPreSetting] = useState();

  useEffect(() => {
    IndexedDBService.initDB()
      .then(() => {
        dispatch(setDbStatus(true));
        const transaction = IndexedDBService.db.transaction([tblMySettings], "readwrite");
        const objectStore = transaction.objectStore(tblMySettings);
        const countRequest = objectStore.count();

        countRequest.onsuccess = function () {
          const count = countRequest.result;
          if (count === 0) {
            PreSetting.forEach((setting) => {
              let obj = {};
              obj = { ...setting };
              IndexedDBService.addItem(obj, tblMySettings)
                .then(() => {})
                .catch(() => {})
                .finally(() => {
                  dispatch(setPreSettings(PreSetting));
                });
            });
          }
        };

        // Get all Data
        IndexedDBService.getAllData(tblMySettings)
          .then((data) => dispatch(setPreSettings(data)))
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));

    // if (!preSetting) {
    //   setPreSetting((prev) => ({ ...prev, id: 1, settings: [...PreSetting] }));
    // }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/edit/:id" element={<AddKirtanStepper />} />
        <Route path="/input" element={<AddKirtanStepper />} />
        <Route path="/" element={<KirtanArea />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
