import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import SwitchComp from "./Switch";
import { Switch } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const SettingModal = ({ open, handleModalToggle }) => {
  const [manualMode, setManualMode] = useState(false);

  const [vmixSettings, setVmixSettings] = useState({
    webControllerUrl: "http://192.168.1.6:8088",
    overlayChannelId: "",
    inputId: "",
    input: "",
    manualMode: false,
    selectedName1: "line1.Text",
    selectedName2: "line2.Text",
  });

  const handleVmixSettingsChange = (event) => {
    event.preventDefault();

    let settings = { ...vmixSettings };
    const value = event.target.value;
    if (value != "-1") {
      settings = {
        ...settings,
        [event.target.name]: value,
      };
      setVmixSettings(settings);
      localStorage.setItem("vmixSettings", JSON.stringify(settings));
    }
  };

  const handleVmixSettingsManualMode = (event) => {
    const value = event.target.checked;
    setManualMode(value);
    localStorage.setItem("manualMode", value);
    localStorage.setItem("vmixSettings", JSON.stringify(vmixSettings));
  };

  useEffect(() => {
    let manualMode = JSON.parse(localStorage.getItem("manualMode"));
    if (manualMode) {
      setManualMode(manualMode);
    }
  }, [manualMode]);
  useEffect(() => {
    let settings = localStorage.getItem("vmixSettings");
    if (settings) {
      settings = JSON.parse(settings);
      setVmixSettings(settings);
    }
  }, []);

  return (
    <Modal open={open} onClose={() => handleModalToggle(false)}>
      <Box
        sx={style}
        className="rounded-2xl overflow-y-auto max-h-screen min-h-[600px]"
      >
        <Box className="flex items-center justify-between w-full bg-blue-500 px-4 py-3 rounded-t-xl">
          <p className="text-white font-medium text-xl">vMix Settings</p>
          <IconButton
            className="text-white"
            onClick={() => handleModalToggle(false)}
          >
            <i className="fa-solid fa-circle-xmark"></i>
          </IconButton>
        </Box>
        <Box className="flex w-full flex-col items-center justify-center px-10 py-4 gap-3">
          <Box className="flex justify-start items-center w-full gap-6">
            <Typography
              className="font-semibold w-1/3"
              sx={{
                fontSize: "17px",
                fontWeight: "600",
              }}
            >
              Web Controller URL
            </Typography>
            <Typography
              className="font-semibold"
              sx={{
                fontSize: "17px",
                fontWeight: "600",
              }}
            >
              :
            </Typography>

            <TextField
              placeholder="http://192.168.1.6:8088"
              size="small"
              className="w-[250px]"
              value={vmixSettings.webControllerUrl}
              onChange={handleVmixSettingsChange}
              name="webControllerUrl"
            />
          </Box>
          <Box className="flex justify-start items-center w-full gap-6">
            <Typography
              className="font-semibold w-1/3"
              sx={{
                fontSize: "17px",
                fontWeight: "600",
              }}
            >
              Overlay Channel ID
            </Typography>
            <Typography
              className="font-semibold"
              sx={{
                fontSize: "17px",
                fontWeight: "600",
              }}
            >
              :
            </Typography>
            <TextField
              placeholder="Channel ID"
              size="small"
              className="w-[250px]"
              value={vmixSettings.overlayChannelId}
              onChange={handleVmixSettingsChange}
              name="overlayChannelId"
              type="number"
            />
          </Box>
          <Box className="flex justify-start items-center w-full gap-6">
            <Typography
              className="font-semibold w-1/3"
              sx={{
                fontSize: "17px",
                fontWeight: "600",
              }}
            >
              Overlay Input ID
            </Typography>
            <Typography
              className="font-semibold"
              sx={{
                fontSize: "17px",
                fontWeight: "600",
              }}
            >
              :
            </Typography>
            <TextField
              placeholder="Input ID"
              size="small"
              className="w-[250px]"
              value={vmixSettings.inputId}
              onChange={handleVmixSettingsChange}
              name="inputId"
              type="number"
            />
          </Box>
          {/* switch */}
          <Box className="flex justify-start items-center w-full gap-6">
            <Typography
              className="font-semibold w-1/3"
              sx={{
                fontSize: "17px",
                fontWeight: "600",
              }}
            >
              vMix Text Input
            </Typography>
            <Typography
              className="font-semibold"
              sx={{
                fontSize: "17px",
                fontWeight: "600",
              }}
            >
              :
            </Typography>
            {/* manual mode */}

            <Switch
              checked={manualMode}
              onChange={handleVmixSettingsManualMode}
              inputProps={{ "aria-label": "controlled" }}
            />
            {/* <SwitchComp
              name={"manualMode"}
              checked={manualMode}
              handleChange={handleVmixSettingsManualMode}
            /> */}
          </Box>
          <Box className="flex justify-start items-center w-full gap-6">
            <Typography
              className="font-semibold w-1/3"
              sx={{
                fontSize: "17px",
                fontWeight: "600",
              }}
            >
              Text Input ID
            </Typography>
            <Typography
              className="font-semibold"
              sx={{
                fontSize: "17px",
                fontWeight: "600",
              }}
            >
              :
            </Typography>
            <TextField
              disabled={!manualMode}
              placeholder="Enter your input"
              size="small"
              className="w-[250px]"
              value={vmixSettings.input}
              onChange={handleVmixSettingsChange}
              name="input"
              type="text"
            />
          </Box>
          <Box className="flex justify-start items-center w-full gap-6">
            <Typography
              className="font-semibold w-1/3"
              sx={{
                fontSize: "17px",
                fontWeight: "600",
              }}
            >
              Line 1 ID
            </Typography>
            <Typography
              className="font-semibold"
              sx={{
                fontSize: "17px",
                fontWeight: "600",
              }}
            >
              :
            </Typography>
            <TextField
              disabled={!manualMode}
              placeholder="Enter Selected Name 1"
              size="small"
              className="w-[250px]"
              value={vmixSettings.selectedName1}
              onChange={handleVmixSettingsChange}
              name="selectedName1"
              type="text"
            />
          </Box>
          <Box className="flex justify-start items-center w-full gap-6">
            <Typography
              className="font-semibold w-1/3"
              sx={{
                fontSize: "17px",
                fontWeight: "600",
              }}
            >
              Line 2 ID
            </Typography>
            <Typography
              className="font-semibold"
              sx={{
                fontSize: "17px",
                fontWeight: "600",
              }}
            >
              :
            </Typography>
            <TextField
              disabled={!manualMode}
              placeholder="Enter Selected Name 2"
              size="small"
              className="w-[250px]"
              value={vmixSettings.selectedName2}
              onChange={handleVmixSettingsChange}
              name="selectedName2"
              type="text"
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SettingModal;
