import React, { useEffect, useState } from "react";
import Markdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import IndexedDBService, { storeName } from "../Utils/DBConfig";
import { setFontFamily, setIsDualLineMode } from "../Slice/settingsSlice";

const KirtanLinePlate = () => {
  const [styles, setStyles] = useState({});
  const dispatch = useDispatch();
  const [currLine, setCurrLine] = useState("");

  const [nextLine, setNextLine] = useState("");

  const [kirtanData, setKirtanData] = useState({});

  const color = useSelector((state) => state.settings.color);

  const height = useSelector((state) => state.settings.height);

  const fontSize = useSelector((state) => state.settings.fontSize);

  const kirtanId = useSelector((state) => state.kirtanIndex.kirtanId);

  const fontFamily = useSelector((state) => state.settings.fontFamily);

  const fontWeight = useSelector((state) => state.settings.fontWeight);

  const currIndex = useSelector((state) => state.kirtanIndex.currIndex);

  const isDualLineMode = useSelector((state) => state.settings.isDualLineMode);

  const shortcutIndex = useSelector((state) => state.kirtanIndex.shortcutIndex);

  const isDbInitialized = useSelector((state) => state.db.isDbInitialized);

  const backgroundColor = useSelector(
    (state) => state.settings.backgroundColor
  );

  const textShadowColor = useSelector(
    (state) => state.settings.textShadowColor
  );

  const textShadowWidth = useSelector(
    (state) => state.settings.textShadowWidth
  );

  const preSettings = useSelector((state) => state.settings.preSettings);

  const selectedId = useSelector((state) => state.settings.selectedId);

  useEffect(() => {
    setStyles({
      backgroundColor,
      color,
      fontFamily,
      fontSize,
      fontWeight,
      height,
      WebkitTextStroke: `${textShadowWidth} ${textShadowColor}`,
    });
  }, [
    backgroundColor,
    color,
    fontFamily,
    fontSize,
    fontWeight,
    height,
    textShadowWidth,
    textShadowColor,
  ]);

  useEffect(() => {
    let index = currIndex;

    if (shortcutIndex !== null && shortcutIndex !== undefined) index = shortcutIndex;

    const currLine =
      kirtanData.length > 0 &&
      kirtanData.find((kirtan) => kirtan.id === Number(kirtanId))?.content[
        index
      ];

    const nextLine =
      kirtanData.length > 0 &&
      isDualLineMode &&
      kirtanData.find((kirtan) => kirtan.id === Number(kirtanId))?.content[
        index + 1
      ];

    if (nextLine) setNextLine(nextLine);
    else setNextLine("");

    if (currLine) setCurrLine(currLine);
    // else setCurrLine("");
  }, [currIndex, kirtanId, kirtanData, shortcutIndex, isDualLineMode]);

  useEffect(() => {
    isDbInitialized &&
      IndexedDBService.getAllData(storeName).then((data) =>
        setKirtanData(data)
      );
  }, [isDbInitialized]);

  const handleManualMode = async (line, fileName) => {
    let vmixSettings = localStorage.getItem("vmixSettings");
    if (vmixSettings) {
      vmixSettings = JSON.parse(vmixSettings);
      let func = "SetText";
      let url = `${vmixSettings.webControllerUrl}/api/?Function=${func}&Input=${vmixSettings.input}&Value=${line}&SelectedName=${fileName}`;
      try {
        await fetch(url, { mode: "no-cors" });
        return true;
      } catch (e) {
        return false;
      }
    }
  };

  useEffect(() => {
    let manualMode = JSON.parse(localStorage.getItem("manualMode"));
    let vmixSettings = localStorage.getItem("vmixSettings");

    if (vmixSettings) {
      let settings = JSON.parse(vmixSettings);
      if (manualMode) {
        handleManualMode(currLine, settings.selectedName1);
        if (isDualLineMode && nextLine.trim().length > 0)
          handleManualMode(nextLine, settings.selectedName2);
      }
    }
  }, [currLine, isDualLineMode, nextLine]);

  return (
    <div className="w-full">
      {preSettings?.map((setting) => {
        if (setting.id === selectedId) {
          dispatch(setIsDualLineMode(setting.isDualLineMode));
          dispatch(setFontFamily(setting.fontFamily));
        }
        return (
          <div key={setting.id}>
            {setting.id === selectedId && (
              <div className="text-center border-black border flex items-center w-full">
                <div
                  className="text-center w-full flex justify-center items-center flex-col gap-3 "
                  style={{
                    backgroundColor: setting.backgroundColor,
                    color: setting.color,
                    fontFamily: setting.fontFamily,
                    fontSize: setting.fontSize,
                    fontWeight: setting.fontWeight,
                    height: setting.height,
                    WebkitTextStroke: `${setting.textShadowWidth} ${setting.textShadowColor}`,
                  }}
                >
                  <Markdown className={`h-[${setting.height}] leading-none`}>
                    {currLine}
                  </Markdown>
                  {setting.isDualLineMode && (
                    <Markdown className={`h-[${setting.height}] leading-none`}>
                      {nextLine}
                    </Markdown>
                  )}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default KirtanLinePlate;
