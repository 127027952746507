import {
  Box,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Slider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsDualLineMode, setPreSettings } from "../Slice/settingsSlice";
import IndexedDBService, { tblMySettings } from "../Utils/DBConfig";
import FontList from "../Utils/FontsList.json";
import ColorPicker from "./ColorPicker";
import SwitchComp from "./Switch";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const PreSettingModal = ({ open, handlePreSettingModal, id }) => {
  const dispatch = useDispatch();
  const preSettings = useSelector((state) => state.settings.preSettings);
  const isDbInitialized = useSelector((state) => state.db.isDbInitialized);
  const [initialSettings, setInitialSettings] = useState({});

  useEffect(() => {
    id && setInitialSettings(preSettings[id - 1]);
  }, [id]);

  useEffect(() => {
    isDbInitialized &&
      IndexedDBService.getAllData(tblMySettings).then((data) => {
        dispatch(setPreSettings(data));
      });
  }, [initialSettings]);

  const handleDualModeToggle = (x, selectedId) => {
    let isDualMode = { ...initialSettings, id: selectedId, isDualLineMode: x };
    dispatch(setIsDualLineMode(x));
    setInitialSettings(isDualMode);
    updateSettings(isDualMode);
  };

  const handleFontSliderChange = (event, selectedId) => {
    let fontSize = { ...initialSettings, id: selectedId, fontSize: `${event.target.value}px` };
    setInitialSettings(fontSize);
    updateSettings(fontSize);
  };

  const handleFontColorChange = (color, selectedId) => {
    let fontColor = { ...initialSettings, id: selectedId, color };
    setInitialSettings(fontColor);
    updateSettings(fontColor);
  };

  const handleFontWeightToggle = (event, selectedId) => {
    let fontWeight = { ...initialSettings, id: selectedId, fontWeight: `${event.target.value}` };
    setInitialSettings(fontWeight);
    updateSettings(fontWeight);
  };

  const handleViewPortBgColorChange = (color, selectedId) => {
    let viewPortColor = { ...initialSettings, id: selectedId, backgroundColor: color };
    setInitialSettings(viewPortColor);
    updateSettings(viewPortColor);
  };

  const handleViewPortHeightSliderChange = (event, selectedId) => {
    let viewPortHeight = { ...initialSettings, id: selectedId, height: `${event.target.value}px` };
    setInitialSettings(viewPortHeight);
    updateSettings(viewPortHeight);
  };

  const handleSelectFontFamilyChange = (event, selectedId) => {
    let fontFamily = { ...initialSettings, id: selectedId, fontFamily: event.target.value };
    setInitialSettings(fontFamily);
    updateSettings(fontFamily);
  };

  const handleTextShadowColorChange = (color, selectedId) => {
    let textShadowColor = { ...initialSettings, id: selectedId, textShadowColor: color };
    setInitialSettings(textShadowColor);
    updateSettings(textShadowColor);
  };

  const handleTextShadowWidthChange = (event, selectedId) => {
    let textShadowWidth = {
      ...initialSettings,
      id: selectedId,
      textShadowWidth: `${event.target.value}px`,
    };
    setInitialSettings(textShadowWidth);
    updateSettings(textShadowWidth);
  };

  // update settings
  const updateSettings = (obj) => {
    isDbInitialized &&
      IndexedDBService.updateItem(obj, tblMySettings)
        .then((data) => {})
        .catch((error) => {
          // console.log(`error in updating settings`, error);
        });
  };

  if (!open) {
    return <></>;
  }

  return (
    <>
      {preSettings?.map((setting) => (
        <div key={setting.id}>
          {setting.id === id && (
            <Modal open={open} onClose={() => handlePreSettingModal(false)}>
              <Box sx={style} className="rounded-2xl overflow-y-auto max-h-screen min-h-[600px]">
                <Box className="flex items-center justify-between w-full bg-blue-500 px-4 py-3 rounded-t-xl">
                  <p className="text-white font-medium text-xl">Plate Setting</p>
                  <IconButton className="text-white" onClick={() => handlePreSettingModal(false)}>
                    <i className="fa-solid fa-circle-xmark"></i>
                  </IconButton>
                </Box>

                <Box className="flex w-full flex-col items-center justify-center px-10 py-4 gap-3">
                  <Box className="flex justify-start items-center w-full gap-6">
                    <Typography
                      className="font-semibold w-1/3"
                      sx={{
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      Font Size
                    </Typography>
                    <Typography
                      className="font-semibold"
                      sx={{
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      :
                    </Typography>
                    <Box sx={{ width: 200 }}>
                      <Slider
                        aria-label="Temperature"
                        valueLabelDisplay="auto"
                        step={2}
                        value={
                          Object.keys(initialSettings).length > 0
                            ? +initialSettings.fontSize.slice(0, -2)
                            : setting.fontSize.slice(0, -2) || 30
                        }
                        onChange={(e) => handleFontSliderChange(e, setting.id)}
                        marks
                        min={30}
                        max={130}
                      />
                    </Box>
                  </Box>
                  <Box className="flex justify-start items-center w-full gap-6">
                    <Typography
                      className="font-semibold w-1/3"
                      sx={{
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      Font Color
                    </Typography>
                    <Typography
                      className="font-semibold"
                      sx={{
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      :
                    </Typography>
                    <ColorPicker
                      color={
                        Object.keys(initialSettings).length > 0
                          ? initialSettings.color
                          : setting.color
                      }
                      handelColor={(e) => handleFontColorChange(e, setting.id)}
                    />
                  </Box>
                  <Box className="flex justify-start items-center w-full gap-6">
                    <Typography
                      className="font-semibold w-1/3"
                      sx={{
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      Font Weight
                    </Typography>
                    <Typography
                      className="font-semibold "
                      sx={{
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      :
                    </Typography>
                    <div className="">
                      <ToggleButtonGroup
                        value={
                          Object.keys(initialSettings).length > 0
                            ? initialSettings.fontWeight
                            : setting.fontWeight
                        }
                        exclusive
                        sx={{
                          height: "35px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        onChange={(e) => handleFontWeightToggle(e, setting.id)}
                        aria-label="viewPortFontWeight"
                      >
                        <ToggleButton value="500" sx={{ textTransform: "none", fontWeight: 500 }}>
                          Thin
                        </ToggleButton>
                        <ToggleButton
                          value="900"
                          aria-label="right aligned"
                          sx={{ textTransform: "none" }}
                        >
                          Thick
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </Box>
                  <Box className="flex justify-start items-center w-full gap-6">
                    <Typography
                      className="font-semibold w-1/3"
                      sx={{
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      View Port Color
                    </Typography>
                    <Typography
                      className="font-semibold"
                      sx={{
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      :
                    </Typography>
                    <ColorPicker
                      color={
                        Object.keys(initialSettings).length > 0
                          ? initialSettings.backgroundColor
                          : setting.backgroundColor
                      }
                      //   handelColor={handleViewPortBgColorChange}

                      handelColor={(e) => handleViewPortBgColorChange(e, setting.id)}
                    />
                  </Box>
                  <Box className="flex justify-start items-center w-full gap-6">
                    <Typography
                      className="font-semibold w-1/3"
                      sx={{
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      View Port Size
                    </Typography>
                    <Typography
                      className="font-semibold"
                      sx={{
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      :
                    </Typography>
                    <Box sx={{ width: 200 }}>
                      <Slider
                        aria-label="Temperature"
                        valueLabelDisplay="auto"
                        step={2}
                        value={
                          Object.keys(initialSettings).length > 0
                            ? +initialSettings.height.slice(0, -2)
                            : setting.height.slice(0, -2) || 40
                        }
                        onChange={(e) => handleViewPortHeightSliderChange(e, setting.id)}
                        marks
                        min={30}
                        max={240}
                      />
                    </Box>
                  </Box>
                  <Box className="flex justify-start items-center w-full gap-6">
                    <Typography
                      className="font-semibold w-1/3"
                      sx={{
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      Font Family
                    </Typography>
                    <Typography
                      className="font-semibold"
                      sx={{
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      :
                    </Typography>

                    <Select
                      value={
                        Object.keys(initialSettings).length > 0
                          ? initialSettings.fontFamily
                          : setting.fontFamily
                      }
                      onChange={(e) => handleSelectFontFamilyChange(e, setting.id)}
                      className="text-[15px] font-[600]"
                      size="small"
                      sx={{ ":focus": { outline: "none" }, outline: "none" }}
                    >
                      {FontList.map((font) => (
                        <MenuItem key={font.value} value={font.value}>
                          {font.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box className="flex justify-start items-center w-full gap-6">
                    <Typography
                      className="font-semibold w-1/3"
                      sx={{
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      Font Shadow Color
                    </Typography>
                    <Typography
                      className="font-semibold"
                      sx={{
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      :
                    </Typography>
                    <ColorPicker
                      color={
                        Object.keys(initialSettings).length > 0
                          ? initialSettings.textShadowColor
                          : setting.textShadowColor
                      }
                      handelColor={(e) => handleTextShadowColorChange(e, setting.id)}
                    />
                  </Box>
                  <Box className="flex justify-start items-center w-full gap-6">
                    <Typography
                      className="font-semibold w-1/3"
                      sx={{
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      Font Shadow Width
                    </Typography>
                    <Typography
                      className="font-semibold"
                      sx={{
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      :
                    </Typography>
                    <Box sx={{ width: 200 }}>
                      <Slider
                        aria-label="Temperature"
                        valueLabelDisplay="auto"
                        step={0.1}
                        value={
                          Object.keys(initialSettings).length > 0
                            ? +initialSettings.textShadowWidth.slice(0, -2)
                            : setting.textShadowWidth.slice(0, -2)
                        }
                        onChange={(e) => handleTextShadowWidthChange(e, setting.id)}
                        marks
                        min={0}
                        max={5}
                      />
                    </Box>
                  </Box>
                  <Box className="flex justify-start items-center w-full gap-6">
                    <Typography
                      className="font-semibold w-1/3"
                      sx={{
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      Dual Line Mode
                    </Typography>
                    <Typography
                      className="font-semibold"
                      sx={{
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      :
                    </Typography>
                    <SwitchComp
                      name="isDualLineMode"
                      value
                      checked={initialSettings.isDualLineMode}
                      handleChange={(e) => handleDualModeToggle(e, setting.id)}
                    />
                  </Box>
                </Box>
              </Box>
            </Modal>
          )}
        </div>
      ))}
    </>
  );
};

export default PreSettingModal;
