import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currIndex: 0,
  kirtanId: 0,
  shortcutIndex: null,
  selectedLine: 0,
};

export const KirtanIndexSlice = createSlice({
  name: "KirtanIndex",
  initialState,
  reducers: {
    setCurrKirtanIndex: (state, action) => {
      state.currIndex = action.payload;
    },
    setKirtanIndex: (state, action) => {
      state.kirtanId = action.payload;
    },
    setShortcutIndex: (state, action) => {
      state.shortcutIndex = action.payload;
    },

    setSelectedLine: (state, action) => {
      state.selectedLine = action.payload;
    },
  },
});

export const {
  setCurrKirtanIndex,
  setKirtanIndex,
  setShortcutIndex,
  setSelectedLine,
  setSelectedPlate,
} = KirtanIndexSlice.actions;

export default KirtanIndexSlice.reducer;
